import React, { useState } from 'react';
import { 
    Alert,
    Box,
    Button, 
    Container, 
    CssBaseline, 
    Paper, 
    Snackbar, 
    TextField, 
    Toolbar, 
    Typography 
} from '@mui/material';
import { BASE_URL } from '../../Constants'; // Adjust the import path as needed
import { getToken, getUserId } from '../../auth/auth.js'; // Adjust the import path as needed

function LMSNewCourse() {
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [duration, setDuration] = useState('');
    const [transcript, setTranscript] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        setError('');
        setSuccess(false);

        // Basic form validation
        if (!title.trim() || !url.trim() || !duration || !transcript) {
            setError('Please fill in all fields and upload a transcript file');
            setIsLoading(false);
            return;
        }

        // Prepare the form data
        const formData = new FormData();
        formData.append('title', title.trim());
        formData.append('url', url.trim());
        formData.append('duration', parseInt(duration, 10));
        formData.append('transcript', transcript);
        formData.append('who_created', getUserId()); // Assuming getUserId() returns the current user's ID
        formData.append('date_created', new Date().toISOString().split('T')[0]);

        try {
            const response = await fetch(`${BASE_URL}/api/submitlmsvideo`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${getToken()}` // Assuming getToken() returns the user's JWT
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Failed to add video');
            }

            const result = await response.json();
            console.log('Video added successfully:', result);
            setSuccess(true);
            // Reset form fields
            setTitle('');
            setUrl('');
            setDuration('');
            setTranscript(null);
        } catch (error) {
            console.error('Error adding video:', error);
            setError(error.message || 'An error occurred while adding the video');
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'application/pdf' || file.type === 'text/plain')) {
            setTranscript(file);
        } else {
            setError('Please upload a PDF or text file');
            event.target.value = null; // Reset the file input
        }
    };

    return (
        <Box 
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
        >
            <CssBaseline />
            <Toolbar />

            <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 4 }}>

                    <Typography variant="h4" gutterBottom>
                        Add New LMS Video
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="title"
                            label="Video Title"
                            name="title"
                            autoFocus
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="url"
                            label="Video URL"
                            name="url"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="duration"
                            label="Video Duration (in seconds)"
                            name="duration"
                            type="number"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                        />

                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2, mb: 2 }}>
                            <input
                                accept=".pdf,.txt"
                                style={{ display: 'none' }}
                                id="transcript-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="transcript-file">
                                <Button variant="contained" component="span">
                                    Upload Transcript (PDF or TXT)
                                </Button>
                            </label>
                            {transcript && (
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    File selected: {transcript.name}
                                </Typography>
                            )}
                        </Box>
                        
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Adding...' : 'Add Video'}
                        </Button>

                    </Box>

                </Paper>
            </Container>

            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>

            <Snackbar open={success} autoHideDuration={6000} onClose={() => setSuccess(false)}>
                <Alert onClose={() => setSuccess(false)} severity="success" sx={{ width: '100%' }}>
                    Video added successfully!
                </Alert>
            </Snackbar>

        </Box>
    );
}

export default LMSNewCourse;