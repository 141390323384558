import React, { useState, useEffect, useRef } from 'react';
import { 
    Box, Container, Paper, Typography, Button, TextField, 
    CircularProgress, LinearProgress, Toolbar, MenuItem, Select,
    List, ListItem, ListItemText, Divider
} from '@mui/material';
import { getToken, getUserId } from '../../auth/auth.js';
import { BASE_URL } from '../../Constants';
import YouTube from 'react-youtube';

function ChatHistoryNavbar({ chatHistory, onThreadSelect }) {
    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {chatHistory.map((thread) => (
                <React.Fragment key={thread.threadId}>
                    <ListItem button onClick={() => onThreadSelect(thread.threadId)}>
                        <ListItemText 
                            primary={`Chat ${new Date(thread.createdAt).toLocaleDateString()}`}
                            secondary={`${thread.lastMessage} (${thread.messageCount} messages)`}
                        />
                    </ListItem>
                    <Divider component="li" />
                </React.Fragment>
            ))}
        </List>
    );
}

function LMSHome() {
    const [videos, setVideos] = useState([]);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [messages, setMessages] = useState([]);
    const [lmsThreadId, setLMSThreadId] = useState(null);
    const [inputMessage, setInputMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const messagesEndRef = useRef(null);
    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [player, setPlayer] = useState(null);
    const lastSentDurationRef = useRef({});
    const [chatHistory, setChatHistory] = useState([]);
    const [isChatHistoryVisible, setIsChatHistoryVisible] = useState(false);
    const [isLoadingVideos, setIsLoadingVideos] = useState(true);
    const [videoError, setVideoError] = useState(null);

    const CLEAR_COMMANDS = ['/clear', 'clear my convo', 'clear chat', 'clear my chat', 'clear', 'clear convo', 'clea'];

    const token = getToken();
    const userId = getUserId();

    useEffect(() => {
        const fetchVideos = async () => {
            setIsLoadingVideos(true);
            try {
                const response = await fetch(`${BASE_URL}/api/getlmsvideos`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setVideos(data);
                setIsLoadingVideos(false);
            } catch (error) {
                console.error('Error fetching videos:', error);
                setVideoError(error.message);
                setIsLoadingVideos(false);
            }
        };

        fetchVideos();
    }, [token]);

    useEffect(() => {
        const loadThreadAndMessages = async () => {
            setIsLoadingMessages(true);
            try {
                let threadId = localStorage.getItem(`lmsThreadId_${userId}`);
                if (!threadId) {
                    threadId = await createThread();
                    if (!threadId) {
                        throw new Error('Failed to create thread');
                    }
                }
                setLMSThreadId(threadId);
                await fetchMessages(threadId);
            } catch (error) {
                console.error('Error loading thread and messages:', error);
                // Handle the error appropriately (e.g., show an error message to the user)
            } finally {
                setIsLoadingMessages(false);
            }
        };
        
        loadThreadAndMessages();
        fetchChatHistory();
    }, [userId]);

    const navigateVideos = (direction) => {
        const newIndex = currentVideoIndex + direction;
        if (newIndex >= 0 && newIndex < videos.length) {
            // loadVideo(newIndex);
            setCurrentVideoIndex(newIndex);
        }
    };

    useEffect(() => { 
        if (player) {
          const interval = setInterval(handleTimeUpdate, 30000); // Check every 30 seconds
          return () => clearInterval(interval);
        }
    }, [player]);

    const onReady = (event) => {
        setPlayer(event.target);
    };

    const handleTimeUpdate = () => {
        if (player && player.getCurrentTime) {
            const currentTime = Math.floor(player.getCurrentTime());
            const videoId = videos[currentVideoIndex].id;
            
            // Only update if the current time is greater than the last sent duration
            if (currentTime > (lastSentDurationRef.current[videoId] || 0)) {
                updateVideoProgress(currentTime, videoId);
            }
        }
    };
      
    const updateVideoProgress = async (currentTime, videoId) => {
        console.log('Updating video progress:', { currentTime, videoId });
        try {
            const response = await fetch(`${BASE_URL}/api/videoprogress`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    userId,
                    videoId: videoId,
                    watchedDuration: currentTime
                }),
            });
          
          const data = await response.json();
          
            if (data.success) {
                // Update the last sent duration
                lastSentDurationRef.current[videoId] = currentTime;
                console.log(data.message); // debug
                console.log('Progress update successful:', data.message);
            } else {
                console.log('Progress update not needed:', data.message);
            }
        } catch (error) {
            console.error('Error updating video progress:', error);
        }
    };

    function getYouTubeId(url) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = url.match(regExp);
        return (match && match[2].length === 11) ? match[2] : null;
    }

    const createThread = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/createLMSthread`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
            if (!response.ok) {
                throw new Error('Failed to create thread');
            }
            const data = await response.json();
            const newThreadId = data.threadId;
            setLMSThreadId(newThreadId);
            localStorage.setItem(`lmsThreadId_${userId}`, newThreadId);
            console.log('Thread created with ID:', newThreadId); // debug
            return newThreadId;
        } catch (error) {
            console.error('Error creating thread:', error);
            return null; // Return null on error
        }
    };

    const fetchMessages = async (threadId) => {
        const idToUse = threadId || lmsThreadId;
        if (!idToUse) return;
      
        try {
          const response = await fetch(`${BASE_URL}/api/getLMSmessages/${idToUse}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            },
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch messages');
          }
      
          const data = await response.json();
          setMessages(data.messages);
        } catch (error) {
          console.error('Error fetching messages:', error);
        }
    };

    const fetchChatHistory = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/getLMSChatHistory`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch chat history');
            }

            const data = await response.json();
            setChatHistory(data.chatHistory);
        } catch (error) {
            console.error('Error fetching chat history:', error);
        }
    };

    const sendMessage = async () => {
        if (!inputMessage.trim() || !lmsThreadId) return;

        const normalizedInput = inputMessage.trim().toLowerCase();
        if (CLEAR_COMMANDS.includes(normalizedInput)) {
            await startNewChat();
            setInputMessage('');
            return;
        }
    
        const newMessage = { role: 'user', content: inputMessage };
        setMessages(prevMessages => [...prevMessages, newMessage]);
        setInputMessage('');
        setIsLoading(true);
    
        try {
            await fetch(`${BASE_URL}/api/sendLMSchatmessage`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    threadId: lmsThreadId,
                    message: inputMessage
                }),
            });
    
            // Fetch updated messages after sending
            await fetchMessages(lmsThreadId);
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const startNewChat = async () => {
        setIsLoadingMessages(true);
        // Clear the existing thread ID from localStorage
        localStorage.removeItem(`lmsThreadId_${userId}`);
        // Clear the existing messages
        setMessages([]);
        // Create a new thread
        const newThreadId = await createThread();
        if (newThreadId) {
            setLMSThreadId(newThreadId);
            await fetchMessages(newThreadId);
        }
        setIsLoadingMessages(false);
    };

    const handleThreadSelect = async (threadId) => {
        setLMSThreadId(threadId);
        await fetchMessages(threadId);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleVideoSelect = (event) => {
        const newIndex = parseInt(event.target.value, 10);
        setCurrentVideoIndex(newIndex);
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const renderVideoSection = () => {
        if (isLoadingVideos) {
            return <CircularProgress />;
        }

        if (videoError) {
            return <Typography color="error">Error loading videos: {videoError}</Typography>;
        }

        if (videos.length === 0) {
            return <Typography>No videos available.</Typography>;
        }

        return (
            <Paper sx={{ width: { xs: '100%', md: '60%' }, p: 2 }}>
                <Typography variant="h5">{videos[currentVideoIndex].title}</Typography>
                <Typography variant="caption">Video {currentVideoIndex + 1} of {videos.length}</Typography>
                <Box sx={{ position: 'relative', pb: '56.25%', height: 0 }}>
                    <YouTube
                        videoId={getYouTubeId(videos[currentVideoIndex].url)}
                        opts={{
                            width: '100%',
                            height: '100%',
                            playerVars: {
                                modestbranding: 1,
                                rel: 0,
                            },
                        }}
                        onReady={onReady}
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    />
                </Box>
                <Typography 
                    variant="body2" 
                    sx={{ 
                        mt: 1, 
                        p: 1, 
                        backgroundColor: 'grey.200', 
                        borderRadius: 1, 
                        fontStyle: 'italic' 
                    }}
                >
                    Note: Your progress will not be saved if you watch directly on YouTube.
                </Typography>
                <Select
                    value={currentVideoIndex}
                    onChange={handleVideoSelect}
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    {videos.map((video, index) => (
                        <MenuItem key={video.id} value={index}>
                            {video.title}
                        </MenuItem>
                    ))}
                </Select>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                    <Button variant="contained" onClick={() => navigateVideos(-1)} disabled={currentVideoIndex === 0}>
                        Previous
                    </Button>
                    <Button variant="contained" onClick={() => navigateVideos(1)} disabled={currentVideoIndex === videos.length - 1}>
                        Next
                    </Button>
                </Box>
            </Paper>
        );
    };

    return (
        <Box 
            sx={{
                backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                flexGrow: 1,
                minHeight: '100vh',
                overflow: 'auto',
            }}
        >
            <Toolbar />
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
                    {renderVideoSection()}
    
                    {/* Chat Section */}
                    <Box sx={{ width: { xs: '100%', md: '40%' }, display: 'flex', flexDirection: 'column' }}>
                        <Paper sx={{ mb: 2, p: 2 }}>
                            <Button 
                                fullWidth
                                onClick={() => setIsChatHistoryVisible(!isChatHistoryVisible)}
                                sx={{ mb: isChatHistoryVisible ? 2 : 0 }}
                            >
                                {isChatHistoryVisible ? 'Hide Chat History' : 'Show Chat History'}
                            </Button>
                            
                            {isChatHistoryVisible && (
                                <Box sx={{ maxHeight: '30vh', overflow: 'auto' }}>
                                    <Typography variant="h6">Chat History</Typography>
                                    <ChatHistoryNavbar chatHistory={chatHistory} onThreadSelect={handleThreadSelect} />
                                </Box>
                            )}
                        </Paper>
                        
                        <Paper sx={{ flexGrow: 1, p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6">Ask Heartee.AI</Typography>
                            <Box sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>
                                {isLoadingMessages ? (
                                    <CircularProgress />
                                ) : (
                                    messages.map((msg, index) => (
                                        <Typography key={index} variant="body1" sx={{ mb: 1 }}>
                                            <strong>{msg.role === 'user' ? 'You: ' : 'Heartee.AI: '}</strong>
                                            {msg.content}
                                        </Typography>
                                    ))
                                )}
                                <div ref={messagesEndRef} />
                            </Box>
                            {isLoading && <LinearProgress sx={{ mb: 2 }} />}
                            <Box component="form" sx={{ display: 'flex' }} onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
                                <TextField 
                                    fullWidth 
                                    variant="outlined" 
                                    placeholder="Type your question..." 
                                    value={inputMessage}
                                    onChange={(e) => setInputMessage(e.target.value)}
                                    sx={{ mr: 1 }} 
                                />
                                <Button variant="contained" type="submit">Send</Button>
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default LMSHome;
