import React, { useState, useEffect, useRef } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, Box, FormControl, FormLabel, TextField, FormControlLabel, Checkbox, MenuItem, Select, FormHelperText, OutlinedInput, InputAdornment, Accordion, AccordionSummary, InputLabel, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { BASE_URL } from '../../Constants';
import { getToken } from '../../auth/auth.js';

const mdTheme = createTheme();

const ContentWrapper = styled('div')({});

function YieldGroup({ 
    index, 
    firstStrain, 
    state, 
    onChange, 
    highlight, 
    isOpen 
}) {

    const { strain = firstStrain, weight = 0, blackBag = false, cwCheck = false } = state;
    const [localWeight, setLocalWeight] = React.useState(null); // Keep a local copy 
    const weightRef = useRef();
    const [strains, setStrains] = useState([]);

    const [isAccordionOpen, setAccordionOpen] = useState(true);
    const [isHighlighted, setIsHighlighted] = useState(false);

    const [weightError, setWeightError] = useState(false);

    const token = getToken(); 

    // Synchronize localWeight with weight prop
    useEffect(() => {
        if (weight !== localWeight) {
            setLocalWeight(weight);
        }
    }, [weight]);

    //strain mappings
    useEffect(() => {
        const fetchStrains = async () => {
            try {
            const response = await fetch(`${BASE_URL}/api/mappings`, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
                }
            });
        
            const data = await response.json();
            setStrains(data.strain);
            } catch (error) {
            console.error('Failed to fetch strains', error);
            }
        };
        
        fetchStrains();
    }, []);  

    const handleChangeStrain = (event) => {
        const newStrain = event.target.value;
        onChange(index, { ...state, strain: newStrain }); 
    }  

    // const handleChangeWeight = (event) => {
    //     const value = event.target.value;
    //     if (value === "0") {
    //       setWeightError(true);
    //     } else {
    //       setWeightError(false);
    //     }
    //     setLocalWeight(value);
    // };  

    // const handleBlurWeight = () => {
    //     if (localWeight === "0") {
    //       setWeightError(true);
    //     } else {
    //       setWeightError(false);
    //       onChange(index, { ...state, weight:localWeight });
    //     }
    // };

    const handleChangeWeight = (event) => {
        const value = event.target.value;
        setWeightError(value === "0");
        setLocalWeight(value);
        onChange(index, { ...state, weight: value });
    };
    
    const handleBlurWeight = () => {
        setWeightError(localWeight === "0");
        onChange(index, { ...state, weight: localWeight });
    };

    const handleBlackBagCheck = (event) => {
        const newBlackBag = event.target.checked;
        onChange(index, { ...state, blackBag: newBlackBag }); 
        console.log(newBlackBag); // debug
    }

    const handleCWCheck = (event) => {
        const newCWCheck = event.target.checked;
        onChange(index, {...state, cwCheck: newCWCheck }); 
        console.log(newCWCheck); // debug
    }
      
    // useEffect(() => {
    //   setLocalWeight(localWeight);
    // }, [localWeight]);

    useEffect(() => {
        if (highlight) {
            setIsHighlighted(true);
            setTimeout(() => {
                setIsHighlighted(false);
            }, 250);
        }
    }, [highlight]);

    useEffect(() => {
        setAccordionOpen(isOpen);
    }, [isOpen]);

    // Select the content of the weight input when focused
    useEffect(() => {
        if (weightRef.current) {
            weightRef.current.addEventListener('focus', () => {
                weightRef.current.select();
            });
        }
    }, []);

    // useEffect(() => {
    //     console.log(`YieldGroup ${index} received props:`, state);
    // }, [index, state]);

    return (
        <ThemeProvider theme={mdTheme}>

            <CssBaseline />
                <ContentWrapper highlighted={highlight}>
                    <Accordion expanded={isAccordionOpen} onChange={() => setAccordionOpen(!isAccordionOpen)}
                      sx={{ 
                        backgroundColor: isHighlighted ? '#d5ebd0' : 'default color',
                        transition: 'background-color 1s ease-out'
                      }}
                    >

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>
                                {strain}, weight: {localWeight || '0'} <br/>
                                {/* blocks: {numOfBlocks} <br/> */}
                                {/* Shelf: {localShelf ? localShelf : 'none'},  */}
                                {/* Sterilization: {sterilization ? sterilization : 'none'} <br/> */}
                                Black bags: {blackBag ? 'Yes' : 'No'}, CW: {cwCheck ? 'Yes' : 'No'}
                            </Typography>
                        </AccordionSummary>

                        <AccordionDetails>

                            {/* Strain */}
                            <Box margin="1em">
                                <FormControl fullWidth variant="outlined">
                                    <FormLabel component="legend">Strain</FormLabel>
                                    <Select
                                        value={strain}
                                        onChange={handleChangeStrain}
                                        defaultValue={firstStrain}
                                    >
                                        {strains.map((strainObj, index) => (
                                            <MenuItem key={index} value={strainObj.strain_name}>{strainObj.strain_name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            {/* Weight */}
                            <Box margin='1em'>
                                <FormControl variant="outlined">
                                    <InputLabel htmlFor="weight">Weight</InputLabel>
                                    <OutlinedInput
                                        id="weight"
                                        value={localWeight || ''}
                                        onChange={handleChangeWeight}
                                        onBlur={handleBlurWeight} 
                                        endAdornment={<InputAdornment position="end">lbs</InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight', 
                                            min: 0.01, // Minimum value to avoid zero, adjust as needed
                                            step: "any", // Allows any decimal value
                                            inputMode: 'decimal', // Ensures numeric keyboard with decimal on mobile devices
                                            type: 'number' // Ensures that only numbers can be entered
                                        }}
                                        inputRef={weightRef}
                                    />
                                        <FormHelperText id="outlined-weight-helper-text" sx={{color:'red'}}>
                                            {weightError ? "Weight cannot be 0!" : ""}
                                        </FormHelperText>
                                </FormControl>
                            </Box>

                            {/* Black bags check */}
                            <FormControlLabel
                                sx={{ ml: 1 }}
                                control={
                                    <Checkbox
                                        checked={blackBag}
                                        onChange={handleBlackBagCheck}
                                    />
                                }
                                label="Black bags"
                            />

                            {/* Cold water check */}
                            <FormControlLabel
                                sx={{ ml: 1 }}
                                control={
                                    <Checkbox
                                        checked={cwCheck}
                                        onChange={handleCWCheck}
                                    />
                                }
                                label="CW"
                            />

                        </AccordionDetails>

                    </Accordion>
                </ContentWrapper>
            
        </ThemeProvider>
    );
}

export default YieldGroup;