import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';  
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import { Box, FormControl, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import _ from 'lodash';
import dayjs from 'dayjs'; 
import { toast } from 'react-toastify';
import { BASE_URL } from '../../Constants';
import { getToken, getFarmId } from '../../auth/auth.js';
import YieldGroup from '../common/YieldGroup';
import { parse, isValid } from 'date-fns';
import { wordsToNumbers } from 'words-to-numbers';

const ContentWrapper = styled('div')({
  flex: '1', // To ensure content takes up remaining space
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column'
});

function YieldInput() {

  const token = getToken(); 
  const farmId = getFarmId();
  const navigate = useNavigate();

  const [harvestDate, setHarvestDate] = React.useState(dayjs());
  const [strainMappings, setStrainMappings] = React.useState({});
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState('');
  const [yieldGroupRefs, setYieldGroupRefs] = useState([React.createRef()]);
  const [firstStrain, setFirstStrain] = useState(''); 
  const [notes, setNotes] = useState('');
  const [strainNamesById, setStrainNamesById] = React.useState({});

  const [openIndex, setOpenIndex] = useState(0);
  const yieldGroupsContainerRef = useRef(null);

  const [yieldGroups, setYieldGroups] = useState([{ 
    strain: firstStrain, 
    weight: 0,
    blackBag: false,
    cwCheck: false,
  }]);

  // For mappings
  React.useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/mappings`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log({ data }); // debug

        const sourceMappings = {};
        data.source.forEach((record) => {
          sourceMappings[record.source_name.toLowerCase()] = record.source_id;
        });

        const strainMappings = {};
        const strainNamesById = {}; // To map strain_id back to strain_name

        data.strain.forEach((record) => {
          const strainNameLower = record.strain_name.toLowerCase();
          strainMappings[strainNameLower] = record.strain_id;

          // Map strain_id to strain_name for reverse lookup if needed
          strainNamesById[record.strain_id] = record.strain_name;

          if (record.strain_shorthand) {
            const shorthandLower = record.strain_shorthand.toLowerCase();
            strainMappings[shorthandLower] = record.strain_id;
          }
        });

        setStrainMappings(strainMappings);
        setStrainNamesById(strainNamesById); // Store reverse mapping if needed

        // Update the first strain value
        if (data.strain.length > 0) {
          setFirstStrain(data.strain[0].strain_name);
        }
      } catch (error) {
        console.error("An error occurred while fetching the mappings:", error);
      }
    };
    fetchMappings();
  }, []);

  const fetchFarms = () => { 
    return fetch(`${BASE_URL}/api/readfarms`, { 
        method: 'GET', 
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text(); 
      })
      .then(data => {
        try {
          const jsonData = JSON.parse(data); 
          setFarms(jsonData);

          // Find the farm that matches the user's farmId
          const userFarm = jsonData.find(farm => farm.farm_id === farmId);

          // If found, set that as the selectedFarm
          if (userFarm) {
            setSelectedFarm(userFarm.farm_id);
          }
        } catch(e) {
          console.error("The response is not JSON. Data:", data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  useEffect(() => {
    if (firstStrain) {  // Make sure firstStrain is not null or undefined
      setYieldGroups(prevYieldGroups => {
        const updatedYieldGroups = [...prevYieldGroups];
        updatedYieldGroups[0].strain = firstStrain;
        return updatedYieldGroups;
      });
    }
  }, [firstStrain]); 

  useEffect(() => {
    const fetchData = async () => {
      await fetchFarms();
    };
    fetchData();
    console.log(yieldGroups);
  }, []);

  useEffect(() => {
    console.log("yieldGroups state updated:", yieldGroups);
  }, [yieldGroups]);

  const addYieldGroup = () => { 
    setYieldGroups(yieldGroups => {
      const newYieldGroups = [...yieldGroups, {
        strain: firstStrain, 
        weight: 0,
        blackBag: false,
        cwCheck: false,
      }];
      setYieldGroupRefs(yieldGroupRefs => [...yieldGroupRefs, React.createRef()]);
      return newYieldGroups;
    });
    setOpenIndex(yieldGroups.length);
  }  

  function removeYieldGroup(index) {
    setYieldGroups(yieldGroups.filter((_, i) => i !== index));
  } 

  const handleYieldGroupChange = (index, newYieldGroupState) => {
    setYieldGroups(prevYieldGroups => {
        const newYieldGroups = [...prevYieldGroups]; // Create a copy of the yieldGroups state
        newYieldGroups[index] = newYieldGroupState; // Replace the state at the given index with the new state
        return newYieldGroups; // Set the new state
    });
  }
  
  const handleHarvestDateChange = (date) => {
    setHarvestDate(date);
  };

  const handleSubmit = async () => {
    const farm_id = selectedFarm;  
  
    const isAnyWeightZero = yieldGroups.some(group => group.weight <= 0);
  
    if (isAnyWeightZero) {
      alert('Weight must be greater than 0!');
      return; 
    }
  
    try {
      // Map inputGroups to new objects
      const mappedYieldInputGroups = yieldGroups.map(group => {
        // Ensure group.strain is a string and convert it to lowercase
        const strainKey = typeof group.strain === 'string' ? group.strain.toLowerCase() : '';
        const strainId = strainMappings[strainKey];
  
        if (!strainId) {
          console.error(`Strain ID not found for strain: "${group.strain}"`);
          alert(`Strain ID not found for strain: "${group.strain}"`);
          return null; // Skip this group or handle as needed
        }
  
        return {
          ...group,
          strain: strainId,  // Use the strain ID
          farm: farm_id,
          weight: group.weight,
          blackBag: group.blackBag,
          cwCheck: group.cwCheck,
          harvestDate: harvestDate.format('YYYY-MM-DD'),
        };
      }).filter(group => group !== null); // Remove any null groups
  
      if (mappedYieldInputGroups.length === 0) {
        alert('No valid yield groups to submit.');
        return;
      }
  
      console.log({ mappedYieldInputGroups });
  
      const response = await fetch(`${BASE_URL}/api/flushinput`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(mappedYieldInputGroups),
      });
    
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.success) {
        navigate('/yieldview');
        toast.success('Success!', {
          autoClose: 2000,  // Auto close after 2 seconds
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error("An error occurred while submitting the data:", error);
    }
  };
  
  // ------------ Textbox autofill --------------------
  const numberWordsToNumbers = (words) => {
    const numbersMap = {
      zero: 0, one: 1, two: 2, three: 3, four: 4, five: 5, six: 6, seven: 7, eight: 8, nine: 9,
      ten: 10, eleven: 11, twelve: 12, thirteen: 13, fourteen: 14, fifteen: 15, sixteen: 16,
      seventeen: 17, eighteen: 18, nineteen: 19, twenty: 20, thirty: 30, forty: 40, fifty: 50,
      sixty: 60, seventy: 70, eighty: 80, ninety: 90, hundred: 100, thousand: 1000, million: 1000000
    };
  
    words = words.toLowerCase().replace(/[^a-z\s-]/g, '').replace(/-/g, ' ');
    const wordsList = words.split(/\s+/);
    let total = 0;
    let current = 0;
  
    for (let word of wordsList) {
      if (numbersMap[word] !== undefined) {
        let value = numbersMap[word];
        if (value === 100) {
          current = current === 0 ? 100 : current * 100;
        } else if (value === 1000 || value === 1000000) {
          current = (current === 0 ? 1 : current) * value;
          total += current;
          current = 0;
        } else {
          current += value;
        }
      }
    }
  
    return total + current;
  };

  const replaceNumberWords = (str) => {
    return wordsToNumbers(str, { fuzzy: false }); // uses lib to get nuber values from words
  };

  const normalizeMonthNames = (str) => {
    const monthAbbreviations = {
      jan: 'Jan', feb: 'Feb', mar: 'Mar', apr: 'Apr', may: 'May', jun: 'Jun',
      jul: 'Jul', aug: 'Aug', sep: 'Sep', sept: 'Sep', oct: 'Oct', nov: 'Nov', dec: 'Dec'
    };

    return str.replace(/\b([a-z]{3,})\.?\b/gi, (match) => {
      const lowerMatch = match.toLowerCase().replace('.', '');
      return monthAbbreviations[lowerMatch] || match;
    });
  };

  const tryParseDate = (str, currentYear) => {
    console.log('Trying to parse:', str);
    const formats = [
      'MM/dd/yyyy', 'M/d/yyyy', 'MM-dd-yyyy', 'M-d-yyyy',
      'MM/dd/yy', 'M/d/yy', 'MM-dd-yy', 'M-d-yy',
      'MMMM d yyyy', 'MMM d yyyy', 'MMMM d', 'MMM d',
      'd MMMM yyyy', 'd MMM yyyy', 'd MMMM', 'd MMM'
    ];

    str = normalizeMonthNames(str);
    str = replaceNumberWords(str);

    let bestMatch = null;

    for (let format of formats) {
      const parsedDate = parse(str, format, new Date());
      if (isValid(parsedDate)) {
        if (!format.includes('yyyy') && !format.includes('yy')) {
          parsedDate.setFullYear(currentYear);
        }
        if (format.includes('yyyy') || !bestMatch) {
          bestMatch = parsedDate;
        }
        if (format.includes('yyyy') || format.includes('yy')) {
          return parsedDate;
        }
      }
    }

    if (bestMatch) {
      // console.log('Best match found:', bestMatch);
      return bestMatch;
    }

    const fallbackDate = new Date(str);
    console.log('Fallback parsing:', str, 'Result:', fallbackDate, 'Valid:', isValid(fallbackDate));
    return isValid(fallbackDate) ? fallbackDate : null;
  };

  const findDateInNotes = (notes, currentYear) => {
    const datePatterns = [
      /\b\d{1,2}[\/\-]\d{1,2}(?:[\/\-]\d{2,4})?\b/g,
      /\b\d{1,2}\s+(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sept?(?:ember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\b/gi,
      /\b(?:Jan(?:uary)?|Feb(?:ruary)?|Mar(?:ch)?|Apr(?:il)?|May|Jun(?:e)?|Jul(?:y)?|Aug(?:ust)?|Sept?(?:ember)?|Oct(?:ober)?|Nov(?:ember)?|Dec(?:ember)?)\s+\d{1,2}(?:\s+\d{2,4})?\b/gi
    ];

    for (let pattern of datePatterns) {
      const matches = notes.match(pattern);
      if (matches) {
        for (let dateStr of matches) {
          const newDate = tryParseDate(dateStr, currentYear);
          if (newDate) {
            return newDate;
          }
        }
      }
    }
    return null;
  };

  const findFarmInNotes = (notes, farms) => {
    for (let farm of farms) {
      if (notes.toLowerCase().includes(farm.farm_name.toLowerCase())) {
        console.log('Farm found:', farm.farm_name);
        return farm.farm_id;
      }
    }
    return null;
  };

  const findStrainInNotes = (words, strainMappings, strainNamesById) => {
    const phrase = words.join(' ').toLowerCase();
    let foundStrains = [];
    for (let [strainKey, strainId] of Object.entries(strainMappings)) {
      const regex = new RegExp(`\\b${strainKey}\\b(?!\\s+bags?)`, 'i');
      if (regex.test(phrase)) {
        foundStrains.push(strainNamesById[strainId]);
      }
    }
    return foundStrains;
  };
  
  const findWeightInNotes = (words) => {
    console.log("Checking for weight in:", words.slice(0, 2).join(' ')); // Log the words being checked
    const weightPattern = /^((?:\d+\.?\d*)|(?:[a-zA-Z-]+))\s*(lbs?|pounds?|kg|kilograms?)$/i;
    const twoWordMatch = words.slice(0, 2).join(' ').match(weightPattern);
    console.log({ twoWordMatch }); // debug
    if (twoWordMatch) {
      try {
        const weight = parseWeight(twoWordMatch[1], twoWordMatch[2]);
        return { weight, wordsUsed: 2 };
      } catch (error) {
        console.error("Error parsing two-word weight:", error);
      }
    }
    const oneWordMatch = words[0].match(/^(\d+\.?\d*)(lbs?|kg)$/i);
    console.log({ oneWordMatch }); // debug
    if (oneWordMatch) {
      try {
        const weight = parseWeight(oneWordMatch[1], oneWordMatch[2]);
        return { weight, wordsUsed: 1 };
      } catch (error) {
        console.error("Error parsing one-word weight:", error);
      }
    }
    return null;
  };

  const parseWeight = (value, unit) => {
    console.log("Parsing weight:", { value, unit }); // Log input
    let weight = /^\d+\.?\d*$/.test(value) ? parseFloat(value) : numberWordsToNumbers(value);
    console.log("Parsed numeric weight:", weight); // Log parsed weight
    if (unit.toLowerCase().startsWith('kg') || unit.toLowerCase().startsWith('kilogram')) {
      weight *= 2.20462; // Convert kg to lbs
      console.log("Converted to lbs:", weight); // Log converted weight
    }
    return weight;
  };

  const parseMultipleEntries = (notes, strainMappings, strainNamesById) => {
    const entries = [];
    let currentEntry = {};
    const words = notes.split(/\s+/);
    
    console.log("Total words:", words.length);
  
    for (let i = 0; i < words.length; i++) {
      const word = words[i].toLowerCase();
      console.log(`Processing word ${i+1}:`, word);

      // Check for black bag
      const nextWord = words[i + 1]?.toLowerCase();
      if (word === 'black' && (nextWord === 'bag' || nextWord === 'bags')) {
        currentEntry.blackBag = true;
        i++; // Skip the next word
        continue;
      }

      // Check for "bb" as black bag
      if (word === 'bb') {
        currentEntry.blackBag = true;
        continue;
      }
  
      const strains = findStrainInNotes([word], strainMappings, strainNamesById);
      // console.log("Found strains:", strains); // debug
  
      if (strains.length > 0) {
        // console.log("Strain found, processing..."); // debug
        const strain = strains[0]; // Process only the first strain found
        if (currentEntry.strain && currentEntry.strain !== strain) {
          // console.log("Pushing current entry:", currentEntry); // debug
          entries.push(currentEntry);
          currentEntry = {};
        }
        currentEntry.strain = strain;
        console.log("Updated current entry:", currentEntry);
        continue; // Move to the next word
      }
  
      console.log("No strain found, checking for weight");
  
      // Check for weight
      const weightResult = findWeightInNotes(words.slice(i));
      console.log({ weightResult });
      if (weightResult) {
        if (currentEntry.weight) {
          console.log("Pushing current entry:", currentEntry);
          entries.push(currentEntry);
          currentEntry = {};
        }
        currentEntry.weight = weightResult.weight;
        i += weightResult.wordsUsed - 1;
        console.log("Added weight to entry:", currentEntry);
        continue;
      } else {
        console.log("No weight found at index", i);
      }
  
      // Check for black bag
      if (/^(black|bb)$/.test(word)) {
        currentEntry.blackBag = true;
        console.log("Added black bag to entry:", currentEntry);
        continue;
      }
  
      // Check for cold water
      if (/^(cw|cold)$/.test(word) && words[i + 1]?.toLowerCase() === 'water') {
        currentEntry.cwCheck = true;
        i++;
        console.log("Added cold water check to entry:", currentEntry);
        continue;
      }
  
      // If no special meaning, add to notes
      if (!currentEntry.notes) {
        currentEntry.notes = word;
      } else {
        currentEntry.notes += ' ' + word;
      }
      console.log("Added to notes:", currentEntry);
    }
  
    if (Object.keys(currentEntry).length > 0) {
      console.log("Pushing final entry:", currentEntry);
      entries.push(currentEntry);
    }
  
    console.log("Final entries:", entries);
    return entries;
  };

  const handleFillForm = () => {
    const currentYear = new Date().getFullYear();
    const processedNotes = replaceNumberWords(notes);
  
    const newDate = findDateInNotes(processedNotes, currentYear);
    const newFarm = findFarmInNotes(processedNotes, farms);

    const entries = parseMultipleEntries(processedNotes, strainMappings, strainNamesById);
  
    // Update harvest date
    if (newDate && isValid(newDate)) {
      const year = newDate.getFullYear();
      if (year < currentYear - 20 || year > currentYear + 20) {
        newDate.setFullYear(currentYear);
      }
      setHarvestDate(dayjs(newDate));
    }
  
    // Update selected farm
    if (newFarm) {
      setSelectedFarm(newFarm);
    }

    console.log("Entries before setting yield groups:", entries);
    setYieldGroups(() => {
      const updatedYieldGroups = entries.map((entry) => {
        const group = {
          strain: entry.strain || firstStrain,
          weight: entry.weight !== undefined ? entry.weight : 0,
          blackBag: entry.blackBag || false,
          cwCheck: entry.cwCheck || false,
        };
        console.log("Created yield group:", group);
        return group;
      });
      console.log("Final updated yield groups:", updatedYieldGroups);
      return updatedYieldGroups.length > 0 ? updatedYieldGroups : [{
        strain: firstStrain,
        weight: 0,
        blackBag: false,
        cwCheck: false,
      }];
    });

    setOpenIndex(-1);
    setTimeout(scrollToBottom, 0);
  };
  
  const clearForm = () => {
    setNotes('');
  }

  const scrollToBottom = () => {
    if (yieldGroupsContainerRef.current) {
      yieldGroupsContainerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };
  
  return (
      <Box
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          minHeight: '100vh',
          overflow: 'auto',
          pt: '6em',

        }}
        
      >
        <CssBaseline />

          <ContentWrapper>

            {/* HARVEST DATE & FARM */}
            <Grid container spacing={1} justifyContent="center">

              {/* HARVEST DATE */}
              <Grid item xs={4} sx={{mb:4, ml: 10}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker 
                    label="Harvest date" 
                    value={harvestDate} 
                    closeOnSelect={true}
                    onChange={handleHarvestDateChange}>
                    <TextField />
                  </DatePicker>
                </LocalizationProvider>
              </Grid>

              {/* FARM SELECT */}
              <Grid item xs={5} sx={{mb:4}}>
                <FormControl fullWidth sx={{ width: '70%' }}>
                  <InputLabel>Farm</InputLabel>
                  <Select
                    value={selectedFarm}
                    onChange={(e) => setSelectedFarm(e.target.value)}
                  >
                    {farms.map((farm, index) => (
                      <MenuItem key={index} value={farm.farm_id}>  
                        {farm.farm_name}  
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

            </Grid>

            {/* NOTES AND FILL FORM BUTTON */}
            <Grid item xs={12} sm={8} md={6} sx={{ mb: 4 }}>
              <Paper elevation={1} sx={{ p: 3 }}>
                <TextField
                  fullWidth
                  label="Enter text to fill form"
                  multiline
                  rows={4}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  variant="outlined"
                  sx={{ mb: 2 }}
                />
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleFillForm}
                    fullWidth
                  >
                    Fill Form
                  </Button>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={clearForm}
                    fullWidth
                  >
                    Clear
                  </Button>
                </Box>
              </Paper>
            </Grid>

            {/* ACCORDION */}
            <div ref={yieldGroupsContainerRef}>
              {yieldGroups.map((yieldGroup, index) =>
                <Box
                  key={index}
                  ref={yieldGroupRefs[index]} 
                  display="flex"
                  alignItems="center"
                  marginBottom="1em"
                >
                  <YieldGroup 
                    index={index} 
                    firstStrain={firstStrain}
                    state={yieldGroup} 
                    isOpen={index === openIndex}
                    onToggle={() => setOpenIndex(index === openIndex ? -1 : index)}
                    onChange={(index, newYieldGroupState) => handleYieldGroupChange(index, newYieldGroupState)} 
                    highlight={index === yieldGroups.length - 1} 
                  />
              
                  {/* Remove button */}
                  {yieldGroups.length > 1 && (
                    <Button 
                      onClick={() => removeYieldGroup(index)} 
                      variant="outlined"
                      sx={{
                        minWidth: "initial", 
                        width: 40, 
                        height: 40, 
                        borderRadius: "50%", 
                        padding: 0, 
                        display: "flex", 
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5">-</Typography>
                    </Button>
                  )}
                </Box>
              )}
            </div>

            {/* Add button */}
            <Button onClick={addYieldGroup} variant="outlined">+Add</Button> 

            {/* Submit button */}
            <Box margin="1em">
                <Button variant="contained" 
                  onClick={handleSubmit} 
                >Submit</Button>
            </Box>

          </ContentWrapper>

      </Box>
  );
}

export default YieldInput;